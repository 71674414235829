//randomize the numbers in the board
export function makeGrid() {
    var distribution = [7,7,7,7,6,6,5,5,4,4,3,3,2,2,2,2,2,2,2,2];
    var values = [];
    for(var vv=0; vv<20; vv++)
        for(var k=0; k<distribution[vv]; k++)
            values.push(vv);

    var grid = [];
    for(var r=0; r<9; r++) {
        var tempRow = [];
        for(var s=0; s<9; s++) {
            var tempCell = [];
            tempRow.push(tempCell);
        }
        grid.push(tempRow);
    }
    grid[4][4].push("info");
    //scroll through each location and pull an item from the list (large values)
    var row=3;
    var col=3;
    for(var v=8; v>0; v--)
    {
        //for each cell, pull a random value from the list
        var index = Math.floor(Math.random()*v)+72;
        var value = values[index];
        grid[row][col].push(value+1);
        values.splice(index,1);
        //update row and col
        if(row===4 & col===3)
        {
            col=5;
        }
        else if(col===5)
        {
            row++;
            col=3;
        }
        else
            col++;
    }
    row=0;
    col=0;
    for(var w=72; w>0; w--)
    {
        //for each cell, pull a random value from the list
        var index2 = Math.floor(Math.random()*w);
        var value2 = values[index2];
        grid[row][col].push(value2+1);
        values.splice(index2,1);
        //update row and col
        if(col===8)
        {
            row++;
            col=0;
        }
        else if(row>2 & row<6)
        {
            if(col===2)
                col=6;
            else
                col++;
        }
        else
            col++;
    }

    //now add border values to every cell, starting with top-right-bottom-left
    for(var r=0; r<9; r++) {
        for(var s=0; s<9; s++) {
            if(r===0)
                grid[r][s].push("2");
            else if(r===3 & s>2 & s<6)
                grid[r][s].push("2");
            else
                grid[r][s].push("1");
        }
    }
    //now for right border
    for(var r=0; r<9; r++) {
        for(var s=0; s<9; s++) {
            if(s===8)
                grid[r][s].push("2");
            else if(s===5 & r>2 & r<6)
                grid[r][s].push("2");
            else
                grid[r][s].push("1");
        }
    }
    //now for bottom
    for(var r=0; r<9; r++) {
        for(var s=0; s<9; s++) {
            if(r===8)
                grid[r][s].push("2");
            else if(r===5 & s>2 & s<6)
                grid[r][s].push("2");
            else
                grid[r][s].push("1");
        }
    }
    //now for left border
    for(var r=0; r<9; r++) {
        for(var s=0; s<9; s++) {
            if(s===0)
                grid[r][s].push("2");
            else if(s===3 & r>2 & r<6)
                grid[r][s].push("2");
            else
                grid[r][s].push("1");
        }
    }

    return grid;
}