import React from 'react';
import './Rounds.css';

function Rounds(props) {
    
    var cls = [];
    for(var i=0; i<props.limit; i++)
        cls.push("Rounds-box");

    for(var i=0; i<props.round; i++)
        cls[i] = "Rounds-box Rounds-completed";
    if(props.round>0 && props.round<props.limit+1)
        cls[props.round-1]="Rounds-box Rounds-active";
    
    return (
        <div className="Rounds">
            <h2>Round</h2>
            {cls.map((value,index) => {
                return <div className={value}><h3>{index+1}</h3></div>
            })}
        </div>
    );
}

export default Rounds;