import React from 'react';
import './Square.css';
import info from './info.png';
import tree from './tree.png';
import bench from './bench.png';
import water from './water.png';
import path from './PathDraw.png';
import error from './error.png';

function Square(props) {
    
    let topBorder = props.topBorder !== undefined ? props.topBorder : "3";
    let rightBorder = props.rightBorder !== undefined ? props.rightBorder : "3";
    let bottomBorder = props.bottomBorder !== undefined ? props.bottomBorder : "3";
    let leftBorder = props.leftBorder !== undefined ? props.leftBorder : "3";
    const label = props.lab;
    const topClass = "Square-topBorder-" + topBorder;
    const rightClass = "Square-rightBorder-" + rightBorder;
    const bottomClass = "Square-bottomBorder-" + bottomBorder;
    const leftClass = "Square-leftBorder-" + leftBorder;
    
    let availableTypes = [];
    if(props.available[0])
        availableTypes.push("tree");
    if(props.available[1])
        availableTypes.push("path");
    if(props.available[2])
        availableTypes.push("water");
    if(props.available[3])
        availableTypes.push("bench");
    
    const tryToPlace = () => {
        if(availableTypes.length>0){
            if(availableTypes.length>1){
                let choice;
                let options = "";
                for(var i=0; i<availableTypes.length; i++){
                    if(i===0)
                        options = options + " " + availableTypes[i];
                    else
                        options = options + ", " + availableTypes[i];
                }
                let ask=true;
                while(ask){
                    choice=prompt("Choose a development type for this square:" + options);
                    for(var j=0; j<availableTypes.length; j++)
                        if(choice===availableTypes[j])
                            ask=false;
                };
                props.place(props.row,props.col,choice);
            }
            else{
                props.place(props.row,props.col,availableTypes[0]);
            }
        }
        else
            alert("You must unlock a development type first!");
    };
    
    if(typeof label === 'number'){
        if(props.active){
            let fullClass = "Square + Square-border + Square-active " + topClass + " " + rightClass + " " + bottomClass + " " + leftClass;
            return (
                <div className={fullClass} onClick={() => tryToPlace()}>
                    <h3>{label}</h3>
                </div>
            );
        }
        else{
            let fullClass = "Square + Square-border " + topClass + " " + rightClass + " " + bottomClass + " " + leftClass;
            return (
                <div className={fullClass}>
                    <h3>{label}</h3>
                </div>
            );            
        }
    }
    else{
        if(label==="info"){
            let fullClass = "Square + Square-border-img " + topClass + " " + rightClass + " " + bottomClass + " " + leftClass + " gray";
            return (
                <div className={fullClass}>
                    <img src={info} alt=""/>
                </div>
            );
        }
        else if(label==="path"){
            let fullClass = "Square + Square-border-img " + topClass + " " + rightClass + " " + bottomClass + " " + leftClass;
            return (
                <div className={fullClass}>
                    <img src={path} alt=""/>
                </div>
            );            
        }
        else if(label==="tree"){
            let fullClass = "Square + Square-border-img " + topClass + " " + rightClass + " " + bottomClass + " " + leftClass;
            return (
                <div className={fullClass}>
                    <img src={tree} alt=""/>
                </div>
            );            
        }
        else if(label==="water"){
            let fullClass = "Square + Square-border-img " + topClass + " " + rightClass + " " + bottomClass + " " + leftClass;
            return (
                <div className={fullClass}>
                    <img src={water} alt=""/>
                </div>
            );            
        }
        else if(label==="bench"){
            let fullClass = "Square + Square-border-img " + topClass + " " + rightClass + " " + bottomClass + " " + leftClass;
            return (
                <div className={fullClass}>
                    <img src={bench} alt=""/>
                </div>
            );            
        }
        else{
            let fullClass = "Square + Square-border-img " + topClass + " " + rightClass + " " + bottomClass + " " + leftClass;
            return (
                <div className={fullClass}>
                    <img src={error} alt=""/>
                </div>
            );            
        }
    }
}

export default Square;
