import React, { useState, useEffect, useCallback} from 'react';
import ReactDOM from 'react-dom';
import {makeGrid} from './components/labels';
import cards from './components/scoringCards';
import tree from './components/tree.png';
import bench from './components/bench.png';
import water from './components/water.png';
import path from './components/path.png';
import Board from './components/Board';
import Developments from './components/Developments';
import Rounds from './components/Rounds';
import Rerolls from './components/Rerolls';
import Cards from './components/Cards';
import Home from './Home';
import {useLocalState} from './components/hooks';
import './App.css';

function App(props) {
    //state/hooks to store status of game
    let falseGrid = [];
    for(var i=0; i<9; i++){
        let falseRow = [];
        for(var j=0; j<9; j++)
            falseRow.push(false);
        falseGrid.push(falseRow);
    }
    
    const [gridValues, updateValue] = useLocalState('grid');
    const [activeGrid, setActiveGrid] = useLocalState('activeGrid');
    const [activeType, setActiveType] = useLocalState('activeTypes');
    const [availableType, setAvailableType] = useLocalState('availType');
    const [diceValues, setDice] = useLocalState('dice');
    const [diceTotal,setDiceTotal] = useLocalState('diceTotal');
    const diceLimits = [4,6,8,10,12,20];
    const roundLimit = 10;
    const modLimit = 12;
    const [round,setRound] = useLocalState('round');
    const [mods,setMods] = useLocalState('usedMods');
    const [selectedDice,setSelectedDice] = useLocalState('selectedDice');
    const [buttonLabel,setButtonLabel] = useLocalState('buttonText');
    const [buttonMode,setButtonMode] =useLocalState('buttonModes');
    const [usedDice,setUsedDice] = useLocalState('usedDice');
    const [changed,setChange] = useLocalState('changed');
    const [saved,setSaved] = useLocalState('saved');
    const [storedState,setStoredState] = useLocalState('storedState');
    const [cardsInPlay,setCards] = useLocalState('cardsUsed');
    const [targetScore,setTarget] = useLocalState('target');
    const [gameType,setGameType] = useState(props.mode);
    const [useExp,setExp] = useState('exp');

    //Choose the scoring cards for this game and put them in an array
    const setupGame = () => {
        setRound(0);
        updateValue(makeGrid());
        setActiveGrid(falseGrid);
        setActiveType([false,false,false,false]);
        setDice([4,6,8,10,12,20]);
        setDiceTotal(0);
        setMods(0);
        setSelectedDice([false,false,false,false,false,false]);
        setButtonLabel("Start game");
        setButtonMode(["inactive","inactive","inactive","active"]);
        setUsedDice([true,true,true,true,true,true]);
        setChange(false);
        setSaved(false);
        setStoredState({});
        setCards([]);
        setTarget(0);
        setExp(false);
        let scoring = [];
        let appropriate = false;
        let first, second, third;
        while(!appropriate){
            //choose three numbers/cards at random
            first = Math.floor(Math.random()*12);
            second = Math.floor(Math.random()*11);
            if(second>=first)
                second=second+1;
            let small = Math.min(first,second);
            let big = Math.max(first,second);
            third = Math.floor(Math.random()*10);
            if(third>=big-1)
                third=third+2;
            else if(third>=small)
                third=third+1;
            //check to see if you have 3 or more dev types
            let numDevs = 0;
            for(var i=0; i<4; i++)
                if(cards[first].devs[i] || cards[second].devs[i] || cards[third].devs[i])
                    numDevs++;
            if(numDevs>2)
                appropriate=true;
        }
        let tempCards = [];
        tempCards.push(cards[first]);
        tempCards.push(cards[second]);
        tempCards.push(cards[third]);
        setCards(tempCards);
        let target = 0;
        target = tempCards[0].target + tempCards[1].target + tempCards[2].target;
        setTarget(target);
    };
    
    if(gameType==='newGame'){
        setupGame();
        setGameType('loaded');
        setButtonLabel("Start new round");
    }
    
    //Here are the structures to save and restore state
    let tempState = {
        grid: [],
        dice: [],
        unlocked: [],
        usedMods: 0,
        usedDice: []
    };
    const restoreState = () => {
        if(saved){
            let tempGrid = JSON.parse(JSON.stringify(storedState.grid));
            let tempDice = JSON.parse(JSON.stringify(storedState.dice));
            let tempUsedDice = JSON.parse(JSON.stringify(storedState.usedDice));
            let tempMods = JSON.parse(JSON.stringify(storedState.usedMods));
            let tempUnlocked = JSON.parse(JSON.stringify(storedState.unlocked));
            updateValue(tempGrid);
            setDice(tempDice);
            setUsedDice(tempUsedDice);
            setMods(tempMods);
            setActiveType(tempUnlocked);
//            console.log(storedState.grid);
            setChange(false);
        }
    };
    
    const place=(row,col,value) => {      
        let temp=[...gridValues];
        temp[row][col][0]=value;
        updateValue(temp);
        let tempDice = [...usedDice];
        for(var i=0; i<selectedDice.length; i++)
            if(selectedDice[i])
                tempDice[i]=true;
        setUsedDice(tempDice);
        setAvailableType("");
        setActiveGrid(falseGrid);
        setSelectedDice([false,false,false,false,false,false]);
        setChange(true);
    };
    
    const rollDice=(roll,roundEnd) => {
        let temp = [...diceValues];
        for(var i=0; i<6; i++){
            if(roll[i]){
                //generate a random number and put it in temp
                let newValue = Math.floor(Math.random()*diceLimits[i])+1;
                temp[i]=newValue;
            }
        }
        setDice(temp);
        setSelectedDice([false,false,false,false,false,false]);
        if(mods<modLimit)
            setButtonMode(["active","active","active","active"]);
        else
            setButtonMode(["inactive","inactive","inactive","active"]);
        setAvailableType("");
        setActiveGrid(falseGrid);
        //update the saved state directly here!
        tempState.grid = JSON.parse(JSON.stringify(gridValues));
        tempState.dice = temp;
        setSaved(true);
        if(roundEnd){
            tempState.unlocked = [false,false,false,false];
            tempState.usedDice = [false,false,false,false,false,false];
            tempState.usedMods = JSON.parse(JSON.stringify(mods));
        }
        else{
            tempState.unlocked = JSON.parse(JSON.stringify(activeType));
            tempState.usedDice = JSON.parse(JSON.stringify(usedDice));
            if(mods===modLimit-1){
                let tempMode = [...buttonMode];
                tempMode[0]="inactive";
                tempMode[1]="inactive";
                tempMode[2]="inactive";
                setButtonMode(tempMode);
            };
            tempState.usedMods = mods+1;
            setMods(mods+1);
        }
        setStoredState(tempState);
        setChange(false);
    };
    
    const useMod= () => {
        if(mods===modLimit-1){
            let temp = [...buttonMode];
            temp[0]="inactive";
            temp[1]="inactive";
            temp[2]="inactive";
            setButtonMode(temp);
        };
        setMods(mods+1);
    };
    
    const newRound=(roll) => {
        if(round<roundLimit){
            rollDice(roll,true);
            setActiveType([false,false,false,false]);
            setUsedDice([false,false,false,false,false,false]);
            if(round===roundLimit-1){
                setButtonLabel("END GAME");
            }
            setRound(round+1);
        }
        else if(round===roundLimit){
            if(window.confirm('MAKE SURE YOU HAVE COMPUTED YOUR SCORE! Then click OK below to reset your game and return to the home screen.')){
                localStorage.clear();
                ReactDOM.render(<Home/>, document.getElementById('root'));
            }
        }   
    };
    
    const modifyDie=(die,value) => {
        let temp = [...diceValues];
        temp[die]=Math.max(temp[die]+value,1);
        setDice(temp);
        setSelectedDice([false,false,false,false,false,false]);
        setAvailableType("");
        setActiveGrid(falseGrid);
    };
    
    const toggleDie = (die) => {
        let temp = [...selectedDice];
        temp[die]=!temp[die];
        setSelectedDice(temp);
        let sum=0;
        for(var i=0; i<6; i++)
            if(temp[i])
                sum=sum+diceValues[i];
        setDiceTotal(sum);
        if(sum>0 && sum<5 && !activeType[0])
            setAvailableType("tree");
        else if(sum>4 && sum<9 && !activeType[1])
            setAvailableType("path");  
        else if(sum>8 && sum<13 && !activeType[2])
            setAvailableType("water");
        else if(sum>12 && !activeType[3])
            setAvailableType("bench");
        else
            setAvailableType("");
        //set the new active grid
        setActiveGrid(falseGrid);
        let tempGrid = [...falseGrid];
        for(var i=0; i<9; i++)
            for(var j=0; j<9; j++)
                if(gridValues[i][j][0]===sum)
                    tempGrid[i][j]=true;
        setActiveGrid(tempGrid);
    };
    
    const activate=(name) => {
        var ind;
        if(name==="tree")
            ind=0;
        if(name==="path")
            ind=1;
        if(name==="water")
            ind=2;
        if(name==="bench")
            ind=3;
        let temp=[...activeType];
        temp[ind]=true;
        setActiveType(temp);
        setAvailableType("");
        setActiveGrid(falseGrid);
        let tempDice = [...usedDice];
        for(var i=0; i<selectedDice.length; i++)
            if(selectedDice[i])
                tempDice[i]=true;
        setUsedDice(tempDice);
        setSelectedDice([false,false,false,false,false,false]);
        setChange(true);
    };
    
    return (
        <div className="App">
            {/* <h1 className="App-title">Polyhedral Park Planner</h1> */}
            <div className="row">
                <div className="med-column">
                    <div className="med-subcolumn">
                        <Board labels={gridValues} available={activeType} place={place} active={activeGrid}/>
                        <Rerolls mods={mods} limit={modLimit}/>
                    </div>
                </div>
                <div className="big-column">
                    <div className="row-small">
                        <div className="med-subcolumn med-special">
                            <Developments choose={activate} active={activeType} dice={diceValues} roll={rollDice} modify={modifyDie} activeDice={selectedDice} activateDie={toggleDie} buttonLabel={buttonLabel} newRound={newRound} buttonMode={buttonMode} useMod={useMod} availableType={availableType} setAvailable={setAvailableType} sum={diceTotal} usedDice={usedDice} changed={changed} restore={restoreState} target={targetScore}/>
                        </div>
                        <div className="small-subcolumn"><Rounds round={round} limit={roundLimit}/></div>
                    </div>
                </div>
            </div>
            <div className="row">
                <Cards cards={cardsInPlay}/>
            </div>
        </div>
    );
    
}

export default App;
