import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import logo from './components/Logo.jpg';
import {useLocalState} from './components/hooks';
import App from './App';
import AppCS from './AppCS';
import Home from './Home';
import expCards from './components/expansionCards';
import './NewGame.css';

function NewGame() {    
    //Click to go to the app
    const baseGame = () => {
        ReactDOM.render(<App mode='newGame'/>, document.getElementById('root'));
    };
    
    const expGame = (city) => {
        console.log(expCards[city].name);
        ReactDOM.render(<AppCS mode='newGame' card={expCards[city]}/>, document.getElementById('root'));    
    };
    
    const goToHome = () => {
        ReactDOM.render(<Home/>, document.getElementById('root')); 
    };
    
    return (
        <div className="NewGame">
            <div className="NewGame-row">
                <div className="col-small-ng">
                    <p>Base Game</p>
                    <div className="col-btn">
                        <button className="btn-base" onClick={() => baseGame()}>Official solo mode</button>
                    </div>
                    <div className="col-btn">
                        <p>Other Options</p>
                    </div>
                    <div className="col-btn">
                        <button className="btn-base" onClick={() => goToHome()}><i className="fas fa-home"></i> Home</button>
                    </div>
                </div>
                <div className="col-huge-ng">
                    <p>Convention Season expansion</p>
                    <div className="NewGame-row">
                        <div className="col-big-ng">
                            <div className="col-btn">
                                <button className="btn" onClick={() => expGame(0)}>1. Lucca, Italy</button>
                            </div>
                            <div className="col-btn">
                                <button className="btn" onClick={() => expGame(1)}>2. Dallas, USA</button>
                            </div>
                            <div className="col-btn">
                                <button className="btn" onClick={() => expGame(2)}>3. Philadelphia, USA</button>
                            </div>
                            <div className="col-btn">
                                <button className="btn" onClick={() => expGame(3)}>4. Canberra, Australia</button>
                            </div>
                        </div>
                        <div className="col-big-ng">
                            <div className="col-btn">
                                <button className="btn" onClick={() => expGame(4)}>5. Cannes, France</button>
                            </div>
                            <div className="col-btn">
                                <button className="btn" onClick={() => expGame(5)}>6. Tokyo, Japan</button>
                            </div>
                            <div className="col-btn">
                                <button className="btn" onClick={() => expGame(6)}>7. Birmingham, UK</button>
                            </div>
                            <div className="col-btn">
                                <button className="btn" onClick={() => expGame(7)}>8. Columbus, USA</button>
                            </div>
                        </div>
                        <div className="col-big-ng">
                            <div className="col-btn">
                                <button className="btn-inactive" disabled onClick={() => expGame(8)}>9. Indianapolis, USA</button>
                            </div>
                            <div className="col-btn">
                                <button className="btn-inactive" disabled onClick={() => expGame(9)}>10. Grand Rapids, USA</button>
                            </div>
                            <div className="col-btn">
                                <button className="btn" onClick={() => expGame(10)}>11. Vancouver, Canada</button>
                            </div>
                            <div className="col-btn">
                                <button className="btn" onClick={() => expGame(11)}>12. Essen, Germany</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewGame;
