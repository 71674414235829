import React from 'react';
import './Action.css';

function Action(props) {
    
    let numUsed=0;
    for(var i=0; i<6; i++)
        if(props.usedDice[i])
            numUsed++;
    
    const makeChange = () => {
        if(props.category==="roll"){
            var numActive = 0;
            for(var i=0; i<props.input.length; i++)
                if(props.input[i])
                    numActive++;
            if(numActive>0){
                props.change(props.input,false);
                props.useMod();
            }
            else
                alert("You must select at least one die to reroll!");
        }
        else{
            //determine if there is exactly one die selected
            console.log("Modify");
            let activeCount = 0;
            let activeDie;
            for(var i=0; i<6; i++)
                if(props.input[i]){
                    activeCount++;
                    activeDie=i;
                }
            if(activeCount===1){
                if(props.dice[activeDie]===1 && props.value===-1)
                    alert("This die cannot be lowered!");
                else{
                    props.change(activeDie,props.value);
                    props.useMod();
                }
            }
            else
                alert("Make sure you have chosen EXACTLY one die to modify!");
        }
    };
    
    if(props.buttonMode==="active" && numUsed<6){
        return (
            <div className="Action small-column">
                <button className={"Action-button btn-" + props.buttonMode} onClick={() => makeChange()}><h3>{props.type}</h3></button>
            </div>
        );
    }
    else{
        return (
            <div className="Action small-column">
                <button disabled className={"Action-button btn-inactive"} onClick={() => makeChange()}><h3>{props.type}</h3></button>
            </div>
        );      
    }
}

export default Action;