import React from 'react';
import './Dice.css';

function Dice(props) {
    if(props.used){
        return (
            <div className={"die-" + props.type + " die small-column die-used"}><h1>X</h1></div>
        );        
    }
    else{
        let cls;
        if(props.active){
            cls = "die-" + props.type + " die small-column die-active";
        }
        else
            cls = "die-" + props.type + " die small-column";

        return (
            <div className={cls} onClick={() => props.activate(props.die)}><h1>{props.value}</h1></div>
        );
    }
}

export default Dice;