import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import logo from './components/Logo.jpg';
import {useLocalState} from './components/hooks';
import App from './App';
import AppCS from './AppCS';
import NewGame from './NewGame';
import expCards from './components/expansionCards';
import './Home.css';

function Home() {
    //Controls new or saved game
    let resume = false;
    if(localStorage.getItem('round')!==null)
        resume = true;
    
    //Click to go to the app
    const checkForNew = () => {
        if(resume){
            if(window.confirm("This will erase your saved game. Are you sure you want to continue?"))
                goToNewGame();
        }
        else
            goToNewGame();
    }
    const goToNewGame = () => {
        localStorage.clear();
        ReactDOM.render(<NewGame/>, document.getElementById('root'));
    };
    
    const goToSavedGame = () => {
        if(JSON.parse(localStorage.getItem('exp')))
            ReactDOM.render(<AppCS mode='loaded' card={expCards[JSON.parse(localStorage.getItem('expCard'))]}/>, document.getElementById('root'));
        else
            ReactDOM.render(<App mode='loaded'/>, document.getElementById('root'));
    };
    
    if(resume){
        return (
            <div className="Home">
                <div className="Home-row">
                    <div className="col-small">
                        <img src={logo} alt="Logo"/>
                    </div>
                    <div className="col-big">
                        <div className="col-btn">
                            <button className="btn" onClick={() => checkForNew()}>Start New Game</button>
                        </div>
                        <div className="col-btn">
                            <button className="btn" onClick={() => goToSavedGame()}>Resume Saved Game</button>
                        </div>
                        <div className="col-btn">
                            <span>Rules:</span>
                            <a target="_BLANK" href="https://boardgamegeek.com/filepage/187813/official-rules"><button className="btn-small">Base Game</button></a>
                            <a target="_BLANK" href="https://boardgamegeek.com/filepage/198686/official-rules"><button className="btn-small">Expansion</button></a>
                        </div>
                        <div className="col-btn">
                            <span>Cards:</span>
                            <a target="_BLANK" href="https://boardgamegeek.com/filepage/187814/scoring-cards"><button className="btn-small">Base Game</button></a>
                            <a target="_BLANK" href="https://boardgamegeek.com/filepage/198687/scoring-cards"><button className="btn-small">Expansion</button></a>
                        </div>
                        <div className="col-btn">
                            <p>Don't forget to <a target="_BLANK" href="https://boardgamegeek.com/boardgame/285789/polyhedral-park-planner">rate Polyhedral Park Planner on BGG</a>!</p>
                        </div>
                        <div className="col-btn">
                            <p>Questions, comments, or suggestions? Visit <a target="_BLANK" href="https://boardgamegeek.com/thread/2426357/play-online-free-new-polyhedral-park-planner-web-a">this app's discussion thread on BGG</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="Home">
                <div className="Home-row">
                    <div className="col-small">
                        <img src={logo} alt="Logo"/>
                    </div>
                    <div className="col-big">
                        <div className="col-btn">
                            <button className="btn" onClick={() => goToNewGame()}>Start New Game</button>
                        </div>
                        <div className="col-btn">
                            <button className="btn-inactive" disabled>Resume Saved Game</button>
                        </div>
                        <div className="col-btn">
                            <a target="_BLANK" href="https://boardgamegeek.com/filepage/187813/official-rules"><button className="btn">Download Rules</button></a>
                        </div>
                        <div className="col-btn">
                            <a target="_BLANK" href="https://boardgamegeek.com/filepage/187814/scoring-cards"><button className="btn">Download Cards</button></a>
                        </div>
                    </div>
                </div>
            </div>
        );        
    }
    
}

export default Home;
