//create array of card objects
var expCards = [];

//add cards one by one
expCards.push({
    city: 0,
    name: "Lucca, Italy",
    scoring: "Build a path loop that encircles the info booth with at least 6 shaded segments. Also build three separate lakes of 3+ squares, each adjacent to at least one bench.",
    budget: [9,2]
});

//1
expCards.push({
    city: 1,
    name: "Dallas, USA",
    scoring: "Build a path from a square adjacent to the info booth to any edge such that this path has no empty adjacent spaces. Also build a lake of 10 or more water squares that is adjacent to at least 3 benches and at least 3 trees.",
    budget: [8,5]
});

//2
expCards.push({
    city: 2,
    name: "Philadelphia, USA",
    scoring: "Build a 4x3 forest, a 3x3 lake, a 2x2 region of benches, and a 1x9 path.",
    budget: [10,6]
});

//3
expCards.push({
    city: 3,
    name: "Canberra, Australia",
    scoring: "Place developments to form five distinct pentominoes (the T, V, W, X, and Z shapes) such that one has only trees, one has only paths, one has only water, one has only benches, and one has at least one of each type.",
    budget: [7,6]
});

expCards.push({
    city: 4,
    name: "Cannes, France",
    scoring: "Use paths to divide your park into four regions, each containing at least 6 non-path squares. One region must contain only trees, one must contain only water, one must contain only benches, and one must be empty. The non-empty regions must be at least half filled.",
    budget: [9,4]
});

//5
expCards.push({
    city: 5,
    name: "Tokyo, Japan",
    scoring: "Fill one 5x5 portion of your park with developments (including the info booth). You must have EXACTLY one lake, one forest, one path, and one connected group of benches, each with at least 4 developments.",
    budget: [8,4]
});

//6
expCards.push({
    city: 6,
    name: "Birmingham, UK",
    scoring: "There are four concentric rings surrounding the info booth. Each ring can only contain one type of development, each development type can only appear in one ring, and (starting from the inside) each ring must contain at least 3/6/9/12 developments.",
    budget: [10,3]
});

expCards.push({
    city: 7,
    name: "Columbus, USA",
    scoring: "Build a river from one corner to the opposite corner; there must be at least 3 trees and 2 benches on either side of this river (but not necessarily adjacent to the river). Build a path connecting one of the remaining corners to the info booth.",
    budget: [10,4]
});


//8
expCards.push({
    city: 8,
    name: "Indianapolis, USA",
    scoring: "Surround each wildlife habitat with a different development type such that your park only contains one forest, one path, one lake, and one region of benches.",
    budget: [10,5]
});

expCards.push({
    city: 9,
    name: "Grand Rapids, USA",
    scoring: "Each wildlife habitat can only have one type of development in its vicinity, and no two habitats can have the same type. At the end of each turn, resolve a Breakout. You must connect each habitat to the info booth.",
    budget: [8,3]
});

expCards.push({
    city: 10,
    name: "Vancouver, Canada",
    scoring: "Build a bench in each corner; each of these benches must contain exactly one tree, one water square, and one path segment in its vicinity.",
    budget: [9,5]
});

expCards.push({
    city: 11,
    name: "Essen, Germany",
    scoring: "Build a lake containing at least 4 islands (an island is a developed region with at least one tree and completely surrounded by water). Build a 3x3 region with benches in each corner and a path filling the remaining 5 squares.",
    budget: [10,6]
});

export default expCards;