import React from 'react';
import './Development.css';

function Development(props) {
    
    const cls = props.active ? "Development-active" : "Development-inactive";
    
    if(props.name===props.available){
        return (
            <div className="Development small-column">
                <div><button className={cls + " Development-available"} onClick={() => props.choose(props.name)}><img src={props.type}/></button></div>
                <div><h3>{props.value}</h3></div>
            </div>
        );        
    }
    else{
        return (
            <div className="Development small-column">
                <div><button className={cls} disabled onClick={() => props.choose(props.name)}><img src={props.type}/></button></div>
                <div><h3>{props.value}</h3></div>
            </div>
        );        
    }
    

}

export default Development;