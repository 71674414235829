import React from 'react';
import Row from './Row';

function Board(props) {
    const labels = props.labels;
    
    return (
        <div>
            <Row label={labels[0]} available={props.available} place={props.place} row={0} active={props.active[0]}/>
            <Row label={labels[1]} available={props.available} place={props.place} row={1} active={props.active[1]}/>
            <Row label={labels[2]} available={props.available} place={props.place} row={2} active={props.active[2]}/>
            <Row label={labels[3]} available={props.available} place={props.place} row={3} active={props.active[3]}/>
            <Row label={labels[4]} available={props.available} place={props.place} row={4} active={props.active[4]}/>
            <Row label={labels[5]} available={props.available} place={props.place} row={5} active={props.active[5]}/>
            <Row label={labels[6]} available={props.available} place={props.place} row={6} active={props.active[6]}/>
            <Row label={labels[7]} available={props.available} place={props.place} row={7} active={props.active[7]}/>
            <Row label={labels[8]} available={props.available} place={props.place} row={8} active={props.active[8]}/>
        </div>
    );
}

export default Board;