import React from 'react';
import ReactDOM from 'react-dom';
import Home from './../Home';
import tree from './tree.png';
import bench from './bench.png';
import water from './water.png';
import path from './path.png';
import './Developments.css';
import Development from './Development';
import Dice from './Dice';
import Action from './Action';
import Advance from './Advance';
import Undo from './Undo';

function Developments(props) {
    
    const goToHome = () => {
        ReactDOM.render(<Home/>, document.getElementById('root')); 
    };
    
    return (
        <div>
            <div className="Developments">
                <Development type={tree} value="1-4" name="tree" choose={props.choose} active={(props.active[0]) ? true : false} available={props.availableType}/>
                <Development type={path} value="5-8" name="path" choose={props.choose} active={(props.active[1]) ? true : false} available={props.availableType}/>
                <Development type={water} value="9-12" name="water" choose={props.choose} active={(props.active[2]) ? true : false} available={props.availableType}/>
                <Development type={bench} value="13+" name="bench" choose={props.choose} active={(props.active[3]) ? true : false} available={props.availableType}/>
            </div>
            <div className="Dice-row">
                <Dice type="d4" value={props.dice[0]} active={props.activeDice[0]} activate={props.activateDie} die={0} used={props.usedDice[0]}/>
                <Dice type="d6" value={props.dice[1]} active={props.activeDice[1]} activate={props.activateDie} die={1} used={props.usedDice[1]}/>
                <Dice type="d8" value={props.dice[2]} active={props.activeDice[2]} activate={props.activateDie} die={2} used={props.usedDice[2]}/>
                <Dice type="d10" value={props.dice[3]} active={props.activeDice[3]} activate={props.activateDie} die={3} used={props.usedDice[3]}/>
                <Dice type="d12" value={props.dice[4]} active={props.activeDice[4]} activate={props.activateDie} die={4} used={props.usedDice[4]}/>
                <Dice type="d20" value={props.dice[5]} active={props.activeDice[5]} activate={props.activateDie} die={5} used={props.usedDice[5]}/>
            </div>
            <div className="Action-row">
                <Action type="Reroll selected dice" input={props.activeDice} change={props.roll} value={0} dice={props.dice} category="roll" buttonMode={props.buttonMode[0]} useMod={props.useMod} usedDice={props.usedDice} save={props.save}/>
                <Action type="Modify selected die +1" input={props.activeDice} change={props.modify} value={1} dice={props.dice} category="modify" buttonMode={props.buttonMode[1]} useMod={props.useMod} usedDice={props.usedDice} save={props.save}/>
                <Action type="Modify selected die -1" input={props.activeDice} change={props.modify} value={-1} dice={props.dice} category="modify" buttonMode={props.buttonMode[2]} useMod={props.useMod} usedDice={props.usedDice} save={props.save}/>
                <Advance type={props.buttonLabel} input={[true,true,true,true,true,true]} change={props.newRound} buttonMode={props.buttonMode[3]} usedDice={props.usedDice}/>
            </div>
            <div className="Action-row">
                <Undo mode={props.changed} restore={props.restore}/>
            </div>
            <div className="Action-target">
                <button onClick={() => goToHome()}><i className="fas fa-home"></i> Home</button>
            </div>
        </div>
    );
}

export default Developments;