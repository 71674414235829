//create array of card objects
var cards = [];

//add cards one by one
cards.push({
    name: "Hundred Acre Wood",
    scoring: "Earn 1 point for every tree in your largest RECTANGULAR forest.",
    requirement: "Your park must contain a forest that includes at least one corner square and at least one square in the vicinity of the info booth.",
    devs: [true,false,false,false],
    target: 12
});

//1
cards.push({
    name: "A Long and Winding Road",
    scoring: "Earn 1 point for every segment in your longest CLOSED path.",
    requirement: "Your park must contain a path at least 5 segments long adjacent to the info booth.",
    devs: [false,true,false,false],
    target: 11
});

//2
cards.push({
    name: "From Sea to Shining Sea",
    scoring: "Earn 1 point per water square in your largest lake.",
    requirement: "Your park must contain EXACTLY one lake.",
    devs: [false,false,true,false],
    target: 13
});

//3
cards.push({
    name: "Best Seats in the House",
    scoring: "Earn 1 point per bench in your park.",
    requirement: "No additional requirement.",
    devs: [false,false,false,true],
    target: 14
});

cards.push({
    name: "Natural Borders",
    scoring: "Earn 2 points for each adjacent pair of one tree and one water square on the boundary of your park. Each development can only be included in one such pair.",
    requirement: "Your park must have at least one boundary edge that is completely developed.",
    devs: [true,false,true,false],
    target: 14
});

//5
cards.push({
    name: "Bridges over Troubled Water",
    scoring: "Earn 3 points for every bridge in your park.",
    requirement: "Your park must have at least one horizontal bridge and at least one vertical bridge.",
    devs: [false,true,true,false],
    target: 15
});

//6
cards.push({
    name: "A Shady Place to Rest",
    scoring: "Earn 2 points for every bench that is adjacent to at least two trees.",
    requirement: "No additional requirement.",
    devs: [true,false,false,true],
    target: 16
});

cards.push({
    name: "Gone Fishin",
    scoring: "For every bench, earn 1 point for every water square in the vicinity of that bench.",
    requirement: "Your park must have a bench with at least 6 water squares in its vicinity.",
    devs: [false,false,true,true],
    target: 18
});


//8
cards.push({
    name: "Center of Attention",
    scoring: "Earn 4 points for every developed square in the vicinity of the info booth.",
    requirement: "Your park must contain at least two different types of developments in the vicinity of the info booth.",
    devs: [false,false,false,false],
    target: 16
});

cards.push({
    name: "A Little Bit of Everything",
    scoring: "Earn 2 points for every complete set of tree/path/water/bench developments in your park.",
    requirement: "Your park must contain at least 5 of each development type.",
    devs: [true,true,true,true],
    target: 14
});

cards.push({
    name: "A Walk to Remember",
    scoring: "Earn 2 points for every segment in your longest SHADED path. A path segment is shaded if it is adjacent to at least one tree.",
    requirement: "Your park must contain at least one shaded path segment in the vicinity of the info booth.",
    devs: [true,true,false,false],
    target: 12
});

cards.push({
    name: "Frequent Pit Stops",
    scoring: "Earn 4/8/12/16/20 points for every connected path that is adjacent to 3/4/5/6/7+ benches.",
    requirement: "Your park must have at least two distinct paths, each adjacent to 3 or more benches.",
    devs: [false,true,false,true],
    target: 20
});

export default cards;