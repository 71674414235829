import React from 'react';
import './Advance.css';

function Advance(props) {
    
    let numUsed=0;
    for(var i=0; i<6; i++)
        if(props.usedDice[i])
            numUsed++;
    
    if(props.buttonMode==="active" && numUsed===6){
        return (
            <div className="Advance small-column">
                <button className={"Advance-button btn-active"} onClick={() => props.change(props.input)}><h3>{props.type}</h3></button>
            </div>
        );
    }
    else{
        return (
            <div className="Advance small-column">
                <button disabled className={"Advance-button btn-inactive"} onClick={() => props.change(props.input)}><h3>{props.type}</h3></button>
            </div>
        );      
    }
}

export default Advance;