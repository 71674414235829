import React from 'react';
import reroll from './QuestionDie.png';
import './Rerolls.css';

function Rerolls(props) {
    
    const cls = [];
    for(var i=0; i<props.mods; i++)
        cls.push("fas fa-check-square");
    for(var i=props.mods; i<props.limit; i++)
        cls.push("far fa-square");
    
    return (
        <div className="Rerolls">
            <img src={reroll} alt="Modification"></img>
            <span>:</span>
            {cls.map((value) => {
                return <i className={value}></i>
            })}
        </div>
    );
}
export default Rerolls;