import React from 'react';
import './ExpCard.css';

function ExpCard(props) {
    
    return (
        <div className="ExpCards">
            <div className="ExpCard">
                <h3><span className="makeBig">{props.card.name}</span> (Budget: {props.card.budget[0]}/{props.card.budget[1]})</h3>
                <p className="ExpCard-score">{props.card.scoring}</p>
            </div>
        </div>
    );
}

export default ExpCard;