import React from 'react';
import './Cards.css';

function Cards(props) {
    
    if(props.cards.length!==3){
        return (
            <div></div>
        );
    }
    
    else{
        return (
            <div className="Cards">
                <div className="Card">
                    <h3>{props.cards[0].name} ({props.cards[0].target})</h3>
                    <p className="Card-score">{props.cards[0].scoring}</p>
                    <p className="Card-req">{props.cards[0].requirement}</p>
                </div>
                <div className="Card">
                    <h3>{props.cards[1].name} ({props.cards[1].target})</h3>
                    <p className="Card-score">{props.cards[1].scoring}</p>
                    <p className="Card-req">{props.cards[1].requirement}</p>
                </div>
                <div className="Card">
                    <h3>{props.cards[2].name} ({props.cards[2].target})</h3>
                    <p className="Card-score">{props.cards[2].scoring}</p>
                    <p className="Card-req">{props.cards[2].requirement}</p>
                </div>
            </div>
        );
    }
}

export default Cards;