import React from 'react';
import Square from './Square';
import './Row.css';

function Row(props) {
    const label = props.label;
    
    return (
        <div className="Row">
            {label.map( (l,index) => <Square lab={l[0]} topBorder={l[1]} rightBorder={l[2]} bottomBorder={l[3]} leftBorder={l[4]}  available={props.available} place={props.place} row={props.row} col={index} active={props.active[index]}/>)}
        </div>
    );
}

export default Row;