import React from 'react';
import './Undo.css';

function Undo(props) {
    
    if(props.mode){
        return (
            <div className="small-column">
                <button className="Action-undo" onClick={() => props.restore()}><h3>Restart round from your previous roll</h3></button>
            </div>
        );
    }
    else{
        return (
            <div className="small-column">
                <button className="Action-undo-inactive"><h3>Restart round from your previous roll</h3></button>
            </div>
        );      
    }
}

export default Undo;